import React from 'react';

export const ButtonWithDropdown = ({
  wrapperClass,
  buttonText,
  buttonClass,
  dropDownClass,
  children,
}) => {
  const [isOpen, toggle] = React.useState(false);

  return (
    <div
      className={`relative inline-flex items-center ml-8 z-20 ${wrapperClass}`}
    >
      <div>
        <span className="rounded-md shadow-sm">
          <button
            onClick={() => {
              toggle(!isOpen);
            }}
            type="button"
            className={`btn btn-primary ${buttonClass}`}
          >
            {buttonText}
            <svg
              className="-mr-1 ml-2 h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              {!isOpen ? (
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  d="M14.707 12.707c-.1876.1875-.4419.2928-.707.2928-.2652 0-.5195-.1053-.707-.2928L9.99997 9.41398l-3.293 3.29302c-.09225.0955-.20259.1717-.3246.2241-.122.0524-.25322.08-.386.0811-.13278.0012-.26446-.0241-.38735-.0744-.1229-.0503-.23455-.1245-.32845-.2184-.09389-.0939-.16814-.2056-.21842-.3285-.05028-.1229-.07559-.2545-.07443-.3873.00115-.1328.02874-.264.08115-.386s.12859-.2324.2241-.3246l4-4.00002c.18753-.18747.44184-.29279.707-.29279.26513 0 .51943.10532.70703.29279l4 4.00002c.1874.1875.2928.4418.2928.707 0 .2651-.1054.5195-.2928.707z"
                  clipRule="evenodd"
                />
              )}
            </svg>
          </button>
        </span>
      </div>
      <div
        className={`origin-top-right absolute right-0 top-0 mt-18 w-56 rounded-md shadow-lg transition transform duration-300 ease-in-our z-10 ${dropDownClass} ${
          isOpen
            ? 'opacity-100 pointer-events-auto translate-y-0'
            : 'opacity-0 pointer-events-none -translate-y-2'
        }`}
      >
        <div className="rounded-md color-mode-background shadow-xs overflow-hidden">
          <div className="">{children}</div>
        </div>
      </div>
    </div>
  );
};
