import React from 'react';
import {Helmet} from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = props => {
  const {
    title,
    description,
    keywords,
    image,
    author,
    type,
    url,
    index,
    context,
    follow,
  } = props;

  const data = useStaticQuery(graphql`
    query DefaultSEOQuery {
      settings: sanitySettings {
        siteName
        title
        titleBase
        description
        keywords
        lang
        baseUrl
        twitterId
        image {
          asset {
            url
          }
        }
      }
    }
  `);

  // URL configuration
  const { baseUrl } = data.settings; // Get base url
  let tempUrl = '';

  if (url) {
    tempUrl = url;
  } else if (
    context &&
    context.slug &&
    context.slug !== '' &&
    context.slug !== '/' &&
    baseUrl
  ) {
    tempUrl = `${baseUrl}/${context.slug}`;
  } else {
    tempUrl = baseUrl || null;
  }

  // Index/Follow configuration
  let tempIndex = '';
  if (index === false) {
    tempIndex = 'noindex';
  } else {
    tempIndex = 'index';
  }

  // Index/Follow configuration
  let tempFollow = '';
  if (follow === false) {
    tempFollow = 'nofollow';
  } else {
    tempFollow = 'follow';
  }

  const metaDescription = description || data.settings.description || '';
  const metaTitle = title || data.settings.title || '';
  const metaAuthor = author || '';
  const metaDefaultTitle = data.settings.title;
  const metaKeywords = keywords || data.settings.keywords || '';
  const metaUrl = tempUrl;
  const metaLang = data.settings.lang || 'en';
  const metaIndex = tempIndex;
  const metaFollow = tempFollow;
  const metaImage =
    (image && image.asset && image.asset.url
      ? `${image.asset.url}?w=630`
      : false) ||
    (data &&
    data.settings &&
    data.settings.image &&
    data.settings.image.asset &&
    data.settings.image.asset.url
      ? `${data.settings.image.asset.url}?w=630`
      : false) ||
    '';
  const metaType = type || 'website';
  const metaTwitterId = data.settings.twitterId || '';

  return (
    <Helmet
      htmlAttributes={{ metaLang }}
      title={metaTitle}
      titleTemplate={
        metaTitle === data.settings.titleBase
          ? '%s'
          : `%s | ${data.settings.titleBase}`
      }
      defaultTitle={metaDefaultTitle}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'author',
          content: metaAuthor,
        },
        {
          property: 'og:type',
          content: metaType,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: metaTwitterId,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'robots',
          content: `${metaIndex}, ${metaFollow}`,
          // TODO: update this
        },
        {
          property: 'og:url',
          content: metaUrl,
        },
        {
          property: 'viewport',
          content: 'width=device-width, initial-scale=1.0',
        },
      ].concat(
        metaKeywords && metaKeywords.length > 0
          ? {
              name: 'keywords',
              content: metaKeywords.join(', '),
            }
          : []
      )}
    >
      {/* <meta property='og:url' content='http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html' />
        <meta property='og:type' content='article' />
        <meta property='og:title' content='When Great Minds Don’t Think Alike' />
        <meta property='og:description' content='How much does culture influence creative thinking?' />
        <meta property='og:image' content='http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg' /> */}
    </Helmet>
  );
};
// Seo.defaultProps = {
//   lang: 'en',
//   meta: [],
//   keywords: []
// }

// Seo.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.array,
//   keywords: PropTypes.arrayOf(PropTypes.string),
//   title: PropTypes.string.isRequired
// }

export default Seo;

export const query = graphql`
  fragment SeoFragment on SanitySeo {
    title
    url
    type
    keywords
    index
    follow
    image {
      asset {
        url
      }
    }
    description
    author
  }
`;
