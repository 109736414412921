import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

import { IconBox } from '@elements';
import BlockContent from '@components/BlockContent';

const ListWithIcons = ({ items, inRichText }) => (
  <ListWithIconsWrap className={`${inRichText && 'mt-10'}`}>
    {items &&
      items.map(item => (
        <li key={item._key} className="mt-4">
          <div className="flex">
            {item.icon &&
              item.icon.iconImage &&
              item.icon.iconImage.asset &&
              item.icon.iconImage.asset.url && (
                <div className="flex-shrink-0 mr-4">
                  <IconBox
                    className=""
                    color={item.icon.color}
                    iconImage={item.icon.iconImage}
                  />
                </div>
              )}

            <ListText className="">
              {item.heading && (
                <h5 className="text-lg leading-6 font-medium font-bold">
                  {item.heading}
                </h5>
              )}

              {item.text && (
                <BlockContent
                  className="mt-6 text-base leading-6"
                  blocks={item.text || []}
                />
              )}
              {/* <p className="mt-2 text-base leading-6">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Maiores impedit perferendis suscipit eaque, iste dolor
                    cupiditate blanditiis ratione.
                  </p> */}
            </ListText>
          </div>
        </li>
      ))}
  </ListWithIconsWrap>
);

export default ListWithIcons;

const ListWithIconsWrap = styled.ul``;
const ListText = styled.div`
  h5 {
    ${tw`mb-0`};
  }
  p {
    ${tw`mt-2 text-base leading-6`};
  }
`;
