import React from 'react';

import { createLocationSlug } from '@utils/locationHelpers';

export const isBlockActive = block => {
  const globalSettingsExists = !!(
    // if block exists.  This state should never exist
    (
      block &&
      // if block globalBlockSettings exists.  This state will exist if any internal settings are not null
      block.globalBlockSettings &&
      // if isActive is false.
      block.globalBlockSettings.isActive === false
    )
  );

  if (globalSettingsExists) {
    return block.globalBlockSettings.isActive;
  }
  return true;
};

export const lineBreakToBr = text => {
  const textWithBrs = [];
  text.split('\n').map((item, key) => {
    textWithBrs.push(
      <span key={key}>
        {item}
        <br />
      </span>
    );
    return null;
  });

  return textWithBrs;
};

// Resolve paths to pages... This will inevitable change... but not yet... not now
// export const LinkPathResolver = slugObject => {
//   if (slugObject && slugObject.__typename) {
//     if (
//       slugObject.__typename === 'SanityLocation' &&
//       slugObject.name &&
//       slugObject.slug &&
//       slugObject.slug.current &&
//       slugObject.group &&
//       slugObject.group.slug &&
//       slugObject.group.slug.current
//     ) {
//       return {
//         name: slugObject.name,
//         slug: `${slugObject.group.slug.current}/${slugObject.slug.current}`,
//       };
//     }
//   } else if (
//     slugObject &&
//     slugObject.pageInfo &&
//     slugObject.pageInfo.slug &&
//     slugObject.pageInfo.slug.current &&
//     slugObject.pageInfo.name
//   ) {
//     return {
//       name: slugObject.pageInfo.name,
//       slug: slugObject.pageInfo.slug.current,
//     };
//   }

//   return null;
// };

const returnLinkAttributes = ({
  anchor,
  text,
  href,
  title,
  key,
  type,
  openNew,
}) => ({
  // This function acts as a middleware to groom and normalize the data if need be
  anchor: `${anchor && `#${anchor}`}`,
  text,
  href,
  title,
  key: key || Math.floor(Math.random() * 1000000) + 1, // if no ket is set, return random value
  type,
  openNew: openNew || null,
});

export const linkResolver = linkObjectArg => {
  // if link
  if (linkObjectArg && linkObjectArg.link && linkObjectArg.link[0]) {
    const linkObject = linkObjectArg.link[0];

    // if link has text and link obj and link obj has type and that a slug exists
    if (linkObject && linkObject._type) {
      const linkText = linkObjectArg.text || '';
      const anchorText =
        (linkObjectArg.anchor && linkObjectArg.anchor.current) || '';

      const linkType = linkObject._type;

      // if link is internal
      if (
        linkType === 'linkInternal' &&
        linkObject.slug &&
        linkObject.slug.pageInfo &&
        linkObject.slug.pageInfo.slug &&
        linkObject.slug.pageInfo.slug.current
      ) {
        let customSlug = null;
        // special routes here
        if (linkObject.slug._type === 'location') {
          customSlug = createLocationSlug(linkObject.slug);
        }

        // return
        return returnLinkAttributes({
          anchor: anchorText,
          text: linkText,
          href: customSlug || linkObject.slug.pageInfo.slug.current,
          title: linkObjectArg.title || linkObject.slug.pageInfo.name || '',
          key: linkObject.id && linkObject.id,
          type: 'internal',
        });
      }

      // if link is external
      if (linkType === 'linkExternal' && linkObject.url) {
        return returnLinkAttributes({
          anchor: anchorText,
          text: linkText,
          href: linkObject.url,
          title: linkObjectArg.title || linkText || '',
          key: linkObject._key && linkObject._key,
          type: 'external',
          openNew: linkObject.openNew && linkObject.openNew,
        });
      }
    }
  }

  return null;
};

// Returns link type
export const returnLinkType = linkObject =>
  linkObject && linkObject && linkObject._type ? linkObject._type : null;

// If link is internal, return true
export const isInternal = linkObject =>
  returnLinkType(linkObject) === 'linkInternal';

// If link is external, return true
export const isExternal = linkObject =>
  returnLinkType(linkObject) === 'linkExternal';

export const hasImage = image =>
  !!(
    image &&
    image.asset &&
    (image.asset.fluid || image.asset.fixed || image.asset.url)
  );

export const determineColumnWith = (maxNumColumns, numOfItems) => {
  // Coming soon?
  // const numOfItems = (props.data.staffMembers.length);
  //     const maxItemsInRow = 3
  //     const numOfRows = Math.ceil(numOfItems / maxItemsInRow);
  //     const itemsInEachRow = Math.ceil(numOfItems / numOfRows);
  //     const columnWidth =  12 / itemsInEachRow ;
  //     const potentialMax = maxItemsInRow * numOfRows;
  //     const remainder = (itemsInEachRow - (potentialMax - numOfItems));
  //     const lastRowColumnWidth =  12 / (Math.ceil(remainder));
  //     const startLastRow = ((numOfRows - 1) * itemsInEachRow);
  //     const fillLastRow  = false;
};

export const evenOrOdd = num => (num % 2 === 1 ? 'odd' : 'even');
