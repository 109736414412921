import React from 'react';
import { graphql } from 'gatsby';

import { Button } from '@elements';

export const ButtonGroup = ({
  buttons,
  backgroundColor,
  hasImageAsBackground,
  className,
  orientation,
}) => {
  let align = 'justify-center';

  if (orientation) {
    if (orientation === 'left') {
      align = 'justify-start';
    }
    if (orientation === 'right') {
      align = 'justify-end';
    }
  }
  if (buttons) {
    return (
      <div
        className={`mt-8 flex flex-col md:flex-row mb-6 items-center ${align} ${className}`}
      >
        {buttons &&
          buttons.map((button, i) => {
            let buttonClasses;
            let buttonGhostClasses;

            if (hasImageAsBackground) {
              buttonClasses = ``;
              buttonGhostClasses = ``;
            } else if (
              backgroundColor === 'primary' ||
              backgroundColor === 'secondary'
            ) {
              buttonClasses = `hover:bg-gray-900 hover:border-gray-900`;
              buttonGhostClasses = `hover:border-white hover:bg-white hover:text-gray-900`;
            } else {
              buttonClasses = ``;
              buttonGhostClasses = ``;
            }

            return (
              <Button
                key={button._key}
                buttonObject={button}
                className={`w-3/4 md:w-auto ${i > 0 &&
                  'mt-3 md:ml-3 md:mt-0'} ${
                  button.isGhost === true ? buttonGhostClasses : buttonClasses
                }`}
              />
            );
          })}
      </div>
    );
  }
  return null;
};
