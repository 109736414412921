/* eslint no-unused-expressions: 0, react/display-name: 0, array-callback-return: 0 */

import React from 'react';
import BaseBlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
// import clientConfig from '../../client-config'
import { YoutubePlayer, Link, Image, ButtonGroup } from '@elements';
import ListWithIcons from '@components/ListWithIcons';

const addContainsMarkClasses = children => {
  let returnStylesArray = [];
  if (children) {
    children.map(child => {
      if (child.marks) {
        child.marks.map(mark => {
          mark === 'center' &&
            returnStylesArray.push('blockContainsCenterText');
          mark === 'textPrimary' &&
            returnStylesArray.push('blockContainsPrimaryText');
          return false;
        });
      }
    });
  }

  returnStylesArray = Array.from(new Set(returnStylesArray));

  return returnStylesArray.join(' ');
};
// const addContainsMarkClasses = props => null;

const serializers = {
  // types: {
  //   block (props) {
  //     switch (props.node.style) {
  //       case 'h1':
  //         return <h1>{props.children}</h1>

  //        // ...

  //       default:
  //         return <p>{props.children}</p>
  //     }
  //   },
  // },
  // marks: {
  //   inlineicon (props) {
  //     switch (props.mark._type){
  //       case 'inlineicon':
  //         if(props.mark.asset) { return <InlineIcon src={props.mark.asset.url || ''} alt={props.children[0]}/> } else { return null }
  //     }
  //   }
  // }
  types: {
    block(props) {
      switch (props.node.style) {
        case 'h1':
          return (
            <h1 className={`${addContainsMarkClasses(props.node.children)}`}>
              {props.children}
            </h1>
          );

        case 'h2':
          return (
            <h2 className={`${addContainsMarkClasses(props.node.children)}`}>
              {props.children}
            </h2>
          );

        case 'h3':
          return (
            <h3 className={`${addContainsMarkClasses(props.node.children)}`}>
              {props.children}
            </h3>
          );

        case 'h4':
          return (
            <h4 className={`${addContainsMarkClasses(props.node.children)}`}>
              {props.children}
            </h4>
          );

        case 'h5':
          return (
            <h5 className={`${addContainsMarkClasses(props.node.children)}`}>
              {props.children}
            </h5>
          );

        case 'h6':
          return (
            <h6 className={`${addContainsMarkClasses(props.node.children)}`}>
              {props.children}
            </h6>
          );

        case 'subHeading':
          return (
            <div
              className={`text-base leading-6 font-semibold uppercase tracking-wider ${addContainsMarkClasses(
                props.node.children[0].marks
              )}`}
            >
              {props.children}
            </div>
          );

        case 'lg':
          return (
            <p
              className={`text-xl leading-7 sm:text-2xl sm:leading-8 ${addContainsMarkClasses(
                props.node.children[0].marks
              )}`}
            >
              {props.children}
            </p>
          );

        case 'xl':
          return (
            <p
              className={`text-2xl leading-8 sm:text-3xl sm:leading-9 ${addContainsMarkClasses(
                props.node.children[0].marks
              )}`}
            >
              {props.children}
            </p>
          );

        default:
          return (
            <p className={`${addContainsMarkClasses(props.node.children)}`}>
              {props.children}
            </p>
          );
      }
    },
    youtube: ({ node }) => {
      const opts = {
        height: '100%',
        width: '100%',
        color: 'white',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
      };
      const { url } = node;
      const options = {
        roundedCorners:
          node && node.roundedCorners ? node.roundedCorners : false,
        shadow: node && node.shadow ? node.shadow : false,
      };
      return <YoutubePlayer opts={opts} url={url} options={options} />;
    },
    // Renders custom list element from a custom Sanity List element
    list: ({ node }) => <ListWithIcons items={node.listItems} inRichText />,
    figureWithOptions: ({ node }) => <Image image={node} imageFromRichText />,
    // Renders buttons from a custom Sanity array of Buttons
    buttonGroup: ({ node }) => (
      <ButtonGroup orientation={node.orientation} buttons={node.buttons} />
    ),
  },
  marks: {
    center: ({ children }) => (
      <span className="text-center block">{children}</span>
    ),
    textPrimary: ({ children }) => (
      <span className="text-primary">{children}</span>
    ),
    link: ({ mark, children }) => (
      <Link link={mark.link} text={children[0]}>
        {children}
      </Link>
    ),
  },
  // marks: {
  //   inlineicon (props) {
  //     switch (props.mark._type){
  //       case 'inlineicon':
  //         if(props.mark.asset) { return <InlineIcon src={props.mark.asset.url || ''} alt={props.children[0]}/> } else { return null }
  //     }
  //   }
  // }
};

const BlockContent = ({ blocks }) => (
  <BaseBlockContentStyled blocks={blocks} serializers={serializers} />
);

const BaseBlockContentStyled = styled(BaseBlockContent)`
  & *:last-child {
    margin-bottom: 0 !important;
  }
`;

export default BlockContent;
