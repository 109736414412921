import React from 'react';
import styled from 'styled-components';

export const Container = ({ children, className, noSpace }) => (
  <ContainerStyled
    className={`container mx-auto px-4 sm:px-6 lg:px-8 ${className} ${
      noSpace ? 'py-0 lg:py-0' : 'py-20'
    }`}
  >
    {children}
  </ContainerStyled>
);

const ContainerStyled = styled.div``;

// max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8
