import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import tw from 'tailwind.macro';
import { getFluidGatsbyImage } from 'gatsby-source-sanity';

export const Image = ({ image, className, imageFromRichText }) => {
  let fluidProps = null;

  if (imageFromRichText && image && image.asset && image.asset._id) {
    const sanityConfig = { projectId: 'phsevku4', dataset: 'production' };
    const imageAssetId = image.asset._id;
    fluidProps = getFluidGatsbyImage(
      imageAssetId,
      { maxWidth: 1024 },
      sanityConfig
    );
  }

  if (image && image.asset && (image.asset.fluid || fluidProps)) {
    return (
      <ImageWithCaptionWrap
        className={`${className} ${imageFromRichText && `my-6`}`}
      >
        <Img
          fluid={
            imageFromRichText && fluidProps ? fluidProps : image.asset.fluid
          }
          alt={image.alt || image.caption}
          className={`relative mx-auto ${image.roundedCorners &&
            'rounded-lg'} ${image.shadow && 'shadow-2xl'}`}
        />
        {image.caption && (
          <figcaption className="text-left text-sm pt-2 opacity-75">
            {image.caption}
          </figcaption>
        )}
      </ImageWithCaptionWrap>
    );
  }
  return null;
};

const ImageWithCaptionWrap = styled.figure`
/* ${tw``}; */

`;
