import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link as GatsbyLink } from 'gatsby';

import LogoSmall from '@images/logoSmall.svg';
import { textColor } from '@theme';
import { Link } from '@elements';

const Footer = () => {
  const footerData = useStaticQuery(graphql`
    query footerQuery {
      sanitySettings {
        footerNavigationPages {
          _key
          ...LinkFragment
        }
      }

      allSanityLocationGroupNew {
        edges {
          node {
            id
            pageInfo {
              name
              slug {
                current
              }
            }
          }
        }
      }

      sanityLocationPage {
        pageInfo {
          name
          slug {
            current
          }
        }
      }
    }
  `);

  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="border-t pt-20 pb-3">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="md:grid md:grid-cols-2 md:gap-8">
          <div className="flex flex-col items-center sm:block lg:col-span-1">
            <GatsbyLink to="/">
              <LogoSmallStyled className="block w-auto" />
            </GatsbyLink>
            <p className="mt-8 text-base leading-6">Tampa Runs on Dunkin'</p>
            <div className="mt-8 flex">
              <a href="/" className="text-inherit">
                <span className="sr-only">Facebook</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M15.5732 5.32h1.9531V2.156c-.94-.10232-1.8851-.1544-2.831-.156-2.7506 0-4.6643 1.68-4.6643 4.72667V9.5H7v3.5547h3.031V22h3.747v-8.9453h2.9507L17.2078 9.5H13.778V7.07867c0-1.05467.3198-1.75867 1.7952-1.75867z" />
                </svg>
              </a>
              <a href="/" className="ml-6 text-inherit">
                <span className="sr-only">Instagram</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a href="/" className="ml-6 text-inherit">
                <span className="sr-only">Twitter</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
              <a href="/" className="ml-6 text-inherit">
                <span className="sr-only">LinkedIn</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M6.79476 21V9.62859H3.2655V21H6.79476ZM5.01135 8.10721C6.14978 8.10721 7.06026 7.15699 7.06026 6.01596C7.06026 4.91257 6.14978 4 5.01135 4C3.91048 4 3 4.91257 3 6.01596C3 7.15699 3.91048 8.10721 5.01135 8.10721ZM19.9987 21V14.7613C19.9987 11.7185 19.3162 9.36118 15.7494 9.36118C14.0411 9.36118 12.9026 10.3114 12.4105 11.1863H12.3716V9.62859H8.9965V21H12.5257V15.3714C12.5257 13.8877 12.7913 12.4805 14.6109 12.4805C16.4332 12.4805 16.4707 14.1551 16.4707 15.4856V21H20H19.9987Z" />
                </svg>
              </a>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-10 sm:grid-cols-2 lg:gap-8 md:mt-0">
            <nav>
              <h4 className="text-sm leading-5 tracking-wider uppercase">
                Company
              </h4>
              <ul className="mt-6">
                {footerData.sanitySettings.footerNavigationPages.map(
                  (item, i) => (
                    <li key={item._key}>
                      <Link
                        link={item}
                        className="text-base leading-6 text-inherit no-underline"
                      />
                    </li>
                  )
                )}
                {/* <li className="mt-2">
                  <a
                    href={footerData.sanitySettings.applyUrl}
                    className="text-primary font-bold underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply Now
                  </a>
                </li> */}
              </ul>
            </nav>
            <nav className="">
              <h4 className="text-sm leading-5 tracking-wider uppercase">
                Locations
              </h4>
              <ul className="mt-6">
                {footerData &&
                  footerData.sanityLocationPage &&
                  footerData.sanityLocationPage.pageInfo &&
                  footerData.sanityLocationPage.pageInfo.slug &&
                  footerData.sanityLocationPage.pageInfo.slug.current &&
                  footerData.allSanityLocationGroupNew &&
                  footerData.allSanityLocationGroupNew.edges &&
                  footerData.allSanityLocationGroupNew.edges.map((edge) => {
                    const group = edge.node;
                    return (
                      <li key={group.id}>
                        {group && group.pageInfo && group.pageInfo.name && (
                          <GatsbyLink
                            to={`/${footerData.sanityLocationPage.pageInfo.slug.current}#${group.pageInfo.slug.current}`}
                            className="text-base leading-6 text-inherit no-underline"
                          >
                            {group.pageInfo.name}
                          </GatsbyLink>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </nav>
            {/* <div className="">
              <h4 className="text-sm leading-5 tracking-wider uppercase">
                Legal
              </h4>
              <ul className="mt-6">
                <li className="mt-2">
                  <a
                    href="/"
                    className="text-base leading-6 text-inherit no-underline"
                  >
                    Terms &amp;
                    <br />
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
      <div className="mt-12 border-t pt-10">
        <p className="text-xs leading-6 text-center mb-0">
          &copy; {year} Tampa Dunkin', Inc. The Dunkin' trademarks, logos and
          designs are trademarks of DD IP Holder LLC. Used under license.
        </p>
        <p className="text-xs leading-6 text-center mb-0">
          All Dunkin' shops are independently owned and operated under a
          franchise granted by Dunkin Brands.
        </p>
        <p className="text-xs leading-6 text-center">
          Website creation by{' '}
          <a
            href="https://allday.io"
            className="text-inherit"
            target="_blank"
            rel="noopener noreferrer"
          >
            AllDay
          </a>{' '}
          🍍
        </p>
      </div>
    </footer>
  );
};

const LogoSmallStyled = styled(LogoSmall)`
  path.textColor {
    fill: ${textColor};
  }
`;

export default Footer;
