import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Link as GatsbyLink } from 'gatsby';

import Marker from '@images/icons/marker.svg';
import { colors } from '@theme';

export const MapMarker = ({ location }) => {
  const [isClicked, toggleClicked] = React.useState(false);

  return (
    <MapMarkerWrap className="relative">
      <MarkerStyled
        className="cursor-pointer relative z-10"
        onClick={() => toggleClicked(!isClicked)}
      />
      <Tooltip
        className={`color-mode-background shadow-lg rounded-lg pointer-events-auto w-80 transition opacity-0 pointer-events-none ${
          isClicked && `opacity-100 pointer-events-auto z-40 relative`
        }`}
      >
        <div className="p-6">
          <div className="flex items-start">
            <div className=" w-0 flex-1 pt-0.5 relative">
              {location.pageInfo.name && (
                <p className="text-lg leading-5 font-bold">
                  {location.pageInfo.name}
                </p>
              )}
              <p className="mt-1 text-sm leading-5">
                {location.address && location.address}
                <br />
                {location.city && location.city},{' '}
                {location.state && location.state}.{' '}
                {location.zip && location.zip}
              </p>
              {location.phone && (
                <p className="mt-1 text-sm leading-5">
                  <strong>Phone:</strong> {location.phone}
                </p>
              )}
              {location.hours && (
                <p className="mt-1 text-sm leading-5">
                  <strong>Hours:</strong>
                  <br />
                  {location.hours}
                </p>
              )}
              {location.pageInfo.slug.current &&
                location.group.pageInfo.slug.current && (
                  <div className="mt-1">
                    <GatsbyLink
                      to={`/${location.group.pageInfo.slug.current}/${location.pageInfo.slug.current}`}
                      className="btn text-sm w-full"
                    >
                      Go to location&lsquo;s page
                    </GatsbyLink>
                  </div>
                )}
              <button
                onClick={() => toggleClicked(!isClicked)}
                type="button"
                className="absolute top-0 right-0 inline-flex text-grey-900 focus:outline-none hover:text-primary focus:text-primary transition ease-in-out duration-150"
              >
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* <div className="rounded-lg shadow-xs overflow-hidden">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <svg
                  className="h-6 w-6 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                  />
                </svg>
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm leading-5 font-medium">
                  Discussion moved
                </p>
                <p className="mt-1 text-sm leading-5 text-gray-500">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit
                  oluptatum tenetur.
                </p>
                <div className="mt-2">
                  <button className="text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    Undo
                  </button>
                  <button className="ml-6 text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    Dismiss
                  </button>
                </div>
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                  <svg
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </Tooltip>
    </MapMarkerWrap>
  );
};

const MapMarkerWrap = styled.div``;

const Tooltip = styled.div`
  transform: translate(calc(-50% + 22px), -120%);
  /* position: absolute;
  left: 100%; */
  ${tw``};
  .on {
    ${tw``};
  }
`;

const MarkerStyled = styled(Marker)`
  path {
    fill: ${colors.primary};
  }

  &:hover path {
    fill: ${colors.secondary};
  }
`;

// const MarkerCreate = () => <Marker />;

// const MarkerWrap = styled.div`
//   cursor: pointer;
//   background-color: green;
//   path {
//     fill: ${colors.primary};
//   }

//   &:hover {
//     path {
//       fill: ${colors.secondary};
//     }
//   }
// `;

// const MarkerNew = ({ to, children }) => (
//   <MarkerWrap>
//     <Link to={to}>
//       {/* {children} */}
//       <MarkerCreate />
//     </Link>
//   </MarkerWrap>
// );
