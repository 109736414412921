import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby';

import { Container, Link, ButtonWithDropdown } from '@elements';
import { textColor, transition } from '@theme/index';

const Nav = () => {
  const headerData = useStaticQuery(graphql`
    query navQuery {
      sanitySettings {
        logoOnLight {
          asset {
            _type
            url
            originalFilename
            extension
          }
        }
        logoOnDark {
          asset {
            _type
            url
            originalFilename
            extension
          }
        }
        navigationPages {
          _key
          ...LinkFragment
        }
        siteName
        stickyNav
      }

      locationSettings: sanityLocationSettings {
        applicationLinkManagers
        applicationLinkEmployees
      }
    }
  `);

  const [isNavOpen, toggleNav] = React.useState(false);

  const themeContext = React.useContext(ThemeContext);

  // Add the bottom border on scroll
  React.useEffect(() => {
    // Get the sticky element
    const navBar = document.querySelector('.navbar');

    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('border-b', e.intersectionRatio < 1),
      { threshold: [1] }
    );

    observer.observe(navBar);
  }, []);

  return (
    <NavStyled
      className={`${headerData.sanitySettings.stickyNav &&
        'sticky '} color-mode-background z-40 navbar`}
    >
      <Container noSpace>
        <div className="flex justify-between h-20">
          <div className="flex justify-between w-full">
            <GatsbyLink to="/" className="flex items-center">
              {headerData.sanitySettings &&
                headerData.sanitySettings.logoOnLight &&
                headerData.sanitySettings.logoOnLight &&
                headerData.sanitySettings.logoOnLight.asset &&
                headerData.sanitySettings.logoOnLight.asset.url &&
                themeContext.mode === 'light' && (
                  <img
                    src={headerData.sanitySettings.logoOnLight.asset.url}
                    alt={headerData.sanitySettings.siteName}
                    className="m-0 object-contain w-auto"
                  />
                )}

              {headerData.sanitySettings &&
                headerData.sanitySettings.logoOnDark &&
                headerData.sanitySettings.logoOnDark &&
                headerData.sanitySettings.logoOnDark.asset &&
                headerData.sanitySettings.logoOnDark.asset.url &&
                themeContext.mode === 'dark' && (
                  <img
                    src={headerData.sanitySettings.logoOnDark.asset.url}
                    alt={headerData.sanitySettings.siteName}
                    className="m-0 object-contain w-auto"
                  />
                )}
            </GatsbyLink>
            <div className="hidden md:-my-px md:ml-6 md:flex text-sm lg:text-base">
              {headerData.sanitySettings.navigationPages.map(item => (
                <NavLink
                  key={item._key}
                  link={item}
                  className="inline-flex items-center px-1 pt-1 ml-4 font-semibold no-underline leading-5 border-b-4 border-transparent lg:ml-8 hover:no-underline hover:border-primary focus:outline-none focus:border-primary"
                  activeClassName="border-primary"
                  partiallyActive
                />
              ))}

              {headerData &&
                headerData.locationSettings &&
                (headerData.locationSettings.applicationLinkManagers ||
                  headerData.locationSettings.applicationLinkEmployees) && (
                  <ButtonWithDropdown
                    buttonText="Apply Now"
                    buttonClass="whitespace-no-wrap"
                  >
                    {headerData.locationSettings.applicationLinkEmployees && (
                      <a
                        className="block px-5 py-3 no-underline color-mode-color-opposite transition duration-300 ease-in-out hover:text-white hover:bg-primary focus:outline-none"
                        href={
                          headerData.locationSettings.applicationLinkEmployees
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Employee Application
                      </a>
                    )}
                    <div className="w-full border-b"></div>
                    {headerData.locationSettings.applicationLinkManagers && (
                      <a
                        className="block px-5 py-3 no-underline color-mode-color-opposite transition duration-300 ease-in-out hover:text-white hover:bg-primary focus:outline-none"
                        href={
                          headerData.locationSettings.applicationLinkManagers
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Manager Application
                      </a>
                    )}
                  </ButtonWithDropdown>
                )}
            </div>
          </div>

          <div className="flex items-center -mr-2 md:hidden ml-6">
            <button
              onClick={() => {
                toggleNav(!isNavOpen);
              }}
              type="button"
              className="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md hover:text-primary focus:outline-none focus:text-primary"
            >
              <svg
                className="w-6 h-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  className={`inline-flex ${isNavOpen && 'hidden'}`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
                <path
                  className={`${!isNavOpen && 'hidden'} text-primary`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </Container>
      <div
        className={`${
          !isNavOpen ? 'hidden' : 'absolute'
        } md:hidden bg-primary z-40 w-full`}
      >
        <div className="">
          {headerData.sanitySettings.navigationPages.map((item, i) => (
            <NavLink
              key={item._key}
              link={item}
              className="block py-4 pl-3 pr-4 text-center text-lg font-semibold text-white no-underline border-b transition duration-150 ease-in-out hover:bg-secondary hover:no-underline focus:outline-none focus:bg-secondary focus:no-underline"
            />
          ))}
          {headerData &&
            headerData.locationSettings &&
            (headerData.locationSettings.applicationLinkManagers ||
              headerData.locationSettings.applicationLinkEmployees) && (
              <>
                {headerData.locationSettings.applicationLinkEmployees && (
                  <a
                    className="block py-4 pl-3 pr-4 text-center text-lg font-semibold text-white no-underline border-b transition duration-150 ease-in-out hover:bg-secondary hover:no-underline focus:outline-none focus:bg-secondary focus:no-underline"
                    href={headerData.locationSettings.applicationLinkEmployees}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Employee Application
                  </a>
                )}
                {headerData.locationSettings.applicationLinkManagers && (
                  <a
                    className="block py-4 pl-3 pr-4 text-center text-lg font-semibold text-white no-underline border-b transition duration-150 ease-in-out hover:bg-secondary hover:no-underline focus:outline-none focus:bg-secondary focus:no-underline"
                    href={headerData.locationSettings.applicationLinkManagers}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Manager Application
                  </a>
                )}
              </>
            )}
        </div>
      </div>
    </NavStyled>
  );
};

export default Nav;

const NavStyled = styled.nav`
  top: -1px; /* hack to fire observer */

  &.border-b {
    padding-top: 1px; /* hack to fire observer */
  }
`;

const NavLink = styled(props => <Link {...props} />)`
  color: ${textColor};
  ${transition};

  &:hover {
    color: ${textColor};
  }
`;

// const LogoStyled = styled(Logo)`
//   path.textColor {
//     fill: ${textColor};
//   }
// `;

// const LogoSmallStyled = styled(LogoSmall)`
//   path.textColor {
//     fill: ${textColor};
//   }
// `;
